import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

export const QUERY_ANALYTICS_BASE = gql`
  query GetAnalyticsBase($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      proxies {
        id
        name
        ip {
          string
        }
        policies {
          id
          name
          key
        }
      }
    }
  }
`;

export const QUERY_ANALYTICS_WAF_COUNTERMEASURE = gql`
  query GetWafAnalyticsCountermeasure(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time!
    $filter: ViolationLogFilterInput
    $groupBy: ViolationLogGroupByInput = { field: DOMAIN, direction: DESCENDING, timeInterval: { interval: 60, unit: MINUTE } }
    $sortBy: [ViolationLogSortBy!] = [{ dimension: DOMAIN, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      wafAnalytics(from: $from, to: $to, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        groups {
          key
          count
        }
      }
    }
  }
`;

export const QUERY_ANALYTICS_BOT_COUNTERMEASURE = gql`
  query GetBotAnalyticsCountermeasure(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time!
    $filter: BotViolationLogFilterInput
    $groupBy: BotViolationLogGroupByInput = { field: DOMAIN, direction: DESCENDING, timeInterval: { interval: 60, unit: MINUTE } }
    $sortBy: [BotViolationLogSortBy!] = [{ dimension: DOMAIN, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      botAnalytics(from: $from, to: $to, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        groups {
          key
          count
        }
      }
    }
  }
`;

export const QUERY_WAF_VIOLATION_LOGS = gql`
  query GetWafViolationLogs(
    $company: CompanyFilterInput
    $page: UnsignedInt32!
    $perPage: UnsignedInt32! = 10000
    $from: Time!
    $to: Time!
    $filter: ViolationLogFilterInput
    $groupBy: ViolationLogGroupByInput = { field: SIGNATURE_NAME, timeInterval: { interval: 60, unit: MINUTE } }
    $sortBy: [ViolationLogSortBy!] = [{ dimension: TIMESTAMP, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      wafAnalytics(from: $from, to: $to, page: $page, perPage: $perPage, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        logs {
          results {
            timestamp
            action
            sourceIP {
              string
            }
            destinationIP {
              string
            }
            site
            reason
            httptxID
            version
            eventID
            sessionID
            signatureID
            signatureName
            protocol
            host
            method
            domain
            uri
            sourceLocation {
              countryName
              city
            }
            profile
            cookies
            userAgent
            rawHeaders
          }
          pageInfo {
            ...PaginationFields
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_BOT_VIOLATION_LOGS = gql`
  query GetBotViolationLogs(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time!
    $page: UnsignedInt32!
    $perPage: UnsignedInt32! = 10000
    $filter: BotViolationLogFilterInput
    $groupBy: BotViolationLogGroupByInput = { field: SIGNATURE_NAME, timeInterval: { interval: 60, unit: MINUTE } }
    $sortBy: [BotViolationLogSortBy!] = [{ dimension: TIMESTAMP, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      botAnalytics(from: $from, to: $to, page: $page, perPage: $perPage, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        logs {
          results {
            timestamp
            action
            sourceIP {
              string
            }
            destinationIP {
              string
            }
            reason
            httptxID
            version
            signatureName
            protocol
            host
            domain
            node {
              iataCode
            }
            uri
            sourceLocation {
              countryName
              city
            }
            profile
            customer
            logType
          }
          pageInfo {
            ...PaginationFields
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_RESPONDER_NETWORK = gql`
  query GetResponderPolicy(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time
    $page: UnsignedInt32!
    $perPage: UnsignedInt32! = 1000
    $filter: ResponderLogFilterInput
    $groupBy: ResponderLogGroupByInput = { field: RESPONDER_NAME, direction: DESCENDING }
    $sortBy: [ResponderLogSortBy!] = [{ dimension: TIMESTAMP, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      responderAnalytics(from: $from, to: $to, page: $page, perPage: $perPage, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        logs {
          results {
            timestamp
            responderAction
            policyKey
            cookies
            customer
            domain
            destinationIP {
              string
            }
            sourceIP {
              string
            }
            host
            method
            destinationPort
            uri
            responderName
            logType
          }
          pageInfo {
            ...PaginationFields
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_IP_INFO = gql`
  query GetIpInfo($address: IPAddressInput!) {
    ipInfo(address: $address) {
      address {
        string
      }
      location {
        areaCode
        city
        continent
        countryCode
        countryName
        latitude
        longitude
        region
        postal
        stateCode
        timezone
      }
      network {
        asn
        carrier
        organization
      }
      reputation {
        realScore
        riskScore
      }
    }
  }
`;

export const QUERY_WAF_ENRICHMENT_LOGS = gql`
  query GetWafEnrichmentLogs(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time!
    $filter: ViolationLogFilterInput
    $groupBy: ViolationLogGroupByInput = { field: SIGNATURE_NAME, timeInterval: { interval: 60, unit: MINUTE } }
    $sortBy: [ViolationLogSortBy!] = [{ dimension: TIMESTAMP, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      wafAnalytics(from: $from, to: $to, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        timeSeriesData {
          cnt
          key
          ts
        }
      }
    }
  }
`;

export const QUERY_BOT_ENRICHMENT_LOGS = gql`
  query GetBotEnrichmentLogs(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time!
    $filter: BotViolationLogFilterInput
    $groupBy: BotViolationLogGroupByInput = { field: SIGNATURE_NAME, timeInterval: { interval: 60, unit: MINUTE } }
    $sortBy: [BotViolationLogSortBy!] = [{ dimension: TIMESTAMP, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      botAnalytics(from: $from, to: $to, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        timeSeriesData {
          cnt
          key
          ts
        }
      }
    }
  }
`;

export const QUERY_WAF_ENRICHMENT_TYPE = gql`
  query GetWafEnrichmentType(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time!
    $filter: ViolationLogFilterInput
    $groupBy: ViolationLogGroupByInput = { field: SIGNATURE_NAME, direction: DESCENDING, timeInterval: { interval: 60, unit: MINUTE } }
    $sortBy: [ViolationLogSortBy!] = [{ dimension: SIGNATURE_NAME, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      wafAnalytics(from: $from, to: $to, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        groups {
          key
          count
        }
      }
    }
  }
`;

export const QUERY_BOT_ENRICHMENT_TYPE = gql`
  query GetBotEnrichmentType(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time!
    $filter: BotViolationLogFilterInput
    $groupBy: BotViolationLogGroupByInput = { field: SIGNATURE_NAME, direction: DESCENDING, timeInterval: { interval: 60, unit: MINUTE } }
    $sortBy: [BotViolationLogSortBy!] = [{ dimension: SIGNATURE_NAME, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      botAnalytics(from: $from, to: $to, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
        groups {
          key
          count
        }
      }
    }
  }
`;

export const QUERY_CONNECTION_PER_VIP = gql`
  query GetConnectionPerVIP(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time
    $filter: AppDataFilterInput
    $fields: [AppDataField!] = [CONNECTIONS, FAILURES, VALIDS, INVALIDS]
    $aggregateBy: AppDataAggregateByInput! = { field: VIP }
  ) {
    company(filter: $company) {
      id
      appDataAnalytics(from: $from, to: $to, filter: $filter, fields: $fields, aggregateBy: $aggregateBy) {
        field
        values {
          key
          count
        }
      }
    }
  }
`;

export const QUERY_METHOD_PER_VIP = gql`
  query GetMethodPerVIP(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time
    $filter: AppDataFilterInput
    $fields: [AppDataField!] = [CONNECTIONS, FAILURES]
    $aggregateBy: AppDataAggregateByInput! = { field: METHOD }
  ) {
    company(filter: $company) {
      id
      appDataAnalytics(from: $from, to: $to, filter: $filter, fields: $fields, aggregateBy: $aggregateBy) {
        field
        values {
          key
          count
        }
      }
    }
  }
`;

export const QUERY_TOP_VIOLATIONS = gql`
  query GetTopViolations($company: CompanyFilterInput, $from: Time!, $to: Time) {
    company(filter: $company) {
      id
      wafAnalytics(from: $from, to: $to, groupBy: { field: DOMAIN }) {
        groups {
          domain: key
          count
        }
      }
      botAnalytics(from: $from, to: $to, filter: { logType: VIOLATION }, groupBy: { field: DOMAIN }) {
        groups {
          domain: key
          count
        }
      }
    }
  }
`;

export const QUERY_BLOCKED_IP_LOGS = gql`
  query DdosBlockedIPLogs($filter: BlockedIPLogFilterInput!, $page: UnsignedInt32!, $perPage: UnsignedInt32!, $sortBy: [BlockedIPLogSortBy!], $companyFilter: CompanyFilterInput) {
    company(filter: $companyFilter) {
      ddosBlockedIPLogs(filter: $filter, page: $page, perPage: $perPage, sortBy: $sortBy) {
        results {
          asn
          blacklisted
          blockedIP {
            strictVersion
            string
            version
          }
          city
          countermeasure
          country
          customer
          destPort
          deviceName
          eventTimeStamp
          firstEventTimeStamp
          internalMitigationName
          nodeName
          prefixList
          protocol
          reason
          rule
          srcPort
        }
        pageInfo {
          itemsPerPage
          pageNumber
          totalItems
        }
      }
    }
  }
`;
