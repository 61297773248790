import React, { PureComponent, lazy } from 'react';

import { CoreMainRouter } from '@neustar/core-ui';
import IndexModule from './Index/';

// CHILD MODULES - (code split)
const HomeModule = lazy(() => import('./Home/'));
const EventsModule = lazy(() => import('./Events/'));
const ConfigurationModule = lazy(() => import('./Configuration/'));
const ManagementModule = lazy(() => import('./Management'));
const AnalyticsModule = lazy(() => import('./Analytics/'));
const StatusCodeModule = lazy(() => import('./StatusCode/'));

/**
 * App Module
 * @class App
 * @extends {PureComponent<{}, {}>}
 */
export default class AppModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof AppModule
   */
  public render() {
    return (
      <CoreMainRouter>
        <IndexModule path="/" />
        <HomeModule path="home" />
        <ManagementModule path="manage/*" />
        <EventsModule path="events/*" />
        <EventsModule path="event/:id" />
        <ConfigurationModule path="configuration/*" />
        <AnalyticsModule path="analytics/*" />
        <StatusCodeModule default path="*" />
      </CoreMainRouter>
    );
  }
}
