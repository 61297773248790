import { ProxyType } from 'Graph';

import { AdjustNavigationSSO } from './AdjustNavigationSSO';

import { CoreAuth, CoreConfigAccount, CoreConfigMeta, CoreNavigation, CorePermissionSimpleInstance, CoreConfigHeader } from '@neustar/core-ui';

/**
 * Adjust Navigation
 * @async
 * @export
 */
export async function AdjustNavigation(): Promise<any> {
  // Cloud Proxy with UltraWAF
  // is the default - no changes

  const debug: boolean = false;

  try {
    if (!CoreNavigation.items.size) {
      throw new ReferenceError('navigation items not instantiated.');
    }

    if (debug && process.env.NODE_ENV !== 'production') {
      console.log('PROXY', CoreConfigAccount.PROXY);
      console.log('PROXY_TYPE', CoreConfigAccount.PROXY_TYPE);
      console.log('WAF', CoreConfigAccount.WAF);
      console.log('BOT', CoreConfigAccount.BOT);
      console.log('ULTRAWAF', CoreConfigAccount.ULTRAWAF);
      console.log('RESELLER', CoreConfigAccount.RESELLER);
      console.log('BGP', CoreConfigAccount.BGP);
      console.log('DNA', CoreConfigAccount.DNA);
      console.log('API_ACCESS', CoreConfigAccount.API_ACCESS);
      console.log('OPENHYBRID', CoreConfigAccount.OPENHYBRID);
      console.log('SSO', CoreConfigAccount.SSO);
      console.log('PROXY_API', CoreConfigAccount.PROXY_API);
      console.log('PROXY_EDGE', CoreConfigAccount.PROXY_EDGE);
    }

    // SSO ENABLED & NOT MASQUERADING
    if (!CoreAuth.user.profile.masquerade) {
      await AdjustNavigationSSO();
    }

    if (CoreConfigAccount.PROXY_API === true){
      const apiUrl = CoreConfigHeader.SHORT_NAME ? `https://ui.${CoreConfigHeader.SHORT_NAME}.portal.ultraapi.com/apisec/api/sso?hint=appsecportal` : '#'; 
      CoreNavigation.items.get('analytics').children.set('analytics.ultra-api', { label: 'UltraAPI', url: apiUrl });
    } else {
      CoreNavigation.items.get('analytics').children.delete('analytics.ultra-api');
    }

    // PROXY_TYPE Configuration
    if (CoreConfigAccount.PROXY_TYPE !== ProxyType.CLOUD) {
      if (CoreNavigation.items.get('analytics').children.has('analytics.bot')) {
        CoreNavigation.items.get('analytics').children.delete('analytics.bot');
      }
    }

    // PROXY Configuration
    if (CoreConfigAccount.PROXY === false) {
      if (CoreNavigation.items.get('analytics').children.has('analytics.proxy')) {
        CoreNavigation.items.get('analytics').children.delete('analytics.proxy');
      }
    }

    // PROXY Only HARDWARE Configuration
    if (CoreConfigAccount.PROXY && CoreConfigAccount.PROXY_TYPE === ProxyType.HARDWARE) {
      if (CoreConfigAccount.WAF === false) {
        if (CoreNavigation.items.get('configuration').children.has('configuration.assets')) {
          CoreNavigation.items.get('configuration').children.set('configuration.assets', { label: 'Proxy', url: '/configuration/proxy' });
        }
        if (CoreNavigation.items.get('configuration').children.has('configuration.policies')) {
          CoreNavigation.items.get('configuration').children.delete('configuration.policies');
        }
        if (CoreNavigation.items.get('configuration').children.has('configuration.signatures')) {
          CoreNavigation.items.get('configuration').children.delete('configuration.signatures');
        }
        if (CoreNavigation.items.get('analytics').children.has('analytics.proxy')) {
          // Hardware label should be "Proxy"
          CoreNavigation.items.get('analytics').children.get('analytics.proxy').label = 'Proxy';
        }
        if (CoreNavigation.items.get('analytics').children.has('analytics.waf')) {
          // Hardware proxy no longer supports WAF; but we still need to show responder policy analytics
          CoreNavigation.items
            .get('analytics')
            .children.get('analytics.waf')
            .children.forEach((val, key) => {
              if (key !== 'analytics.waf.logs') {
                CoreNavigation.items.get('analytics').children.get('analytics.waf').children.delete(key);
              }
            });
          if (CoreNavigation.items.get('analytics').children.get('analytics.waf').children.has('analytics.waf.logs')) {
            // Hardware proxy no longer supports WAF; but we still need to show responder policy analytics
            CoreNavigation.items
              .get('analytics')
              .children.get('analytics.waf')
              .children.get('analytics.waf.logs')
              .children.forEach((val, key) => {
                if (key !== 'analytics.waf.logs.responder-policy') {
                  CoreNavigation.items.get('analytics').children.get('analytics.waf').children.get('analytics.waf.logs').children.delete(key);
                }
              });
          }
        }
      }

      // WAF is not a factor in determining SSL Certificate navigation
      CoreNavigation.items.get('configuration').children.set('configuration.certificates', { label: 'SSL Certificates', url: '/configuration/security/ssl' });

      if (CoreConfigAccount.ULTRAWAF) {
        CoreNavigation.items.get('configuration').children.set('configuration.network-controls', { label: 'Network Controls', url: '/configuration/security/network-controls' });
      } else {
        CoreNavigation.items
          .get('configuration')
          .children.set('configuration.responder-policy', { label: 'HTTP Responder Policy', url: '/configuration/security/http-responder-policy' })
          .set('configuration.network-controls', { label: 'Network Controls', url: '/configuration/security/network-controls' });
      }

      if (CoreNavigation.items.get('analytics').children.has('analytics.bot')) {
        CoreNavigation.items.get('analytics').children.delete('analytics.bot');
      }
    }

    CoreNavigation.items
      .get('configuration')
      .children.set('configuration.managed-objects', { label: 'Managed Objects', path: 'configuration/managed-objects' })
      .set('configuration.filterlists', { label: 'DDoS Filter Lists', path: 'configuration/filterlists' })
      .set('configuration.runbook', { label: 'Run Book', path: 'configuration/runbook' });

    // PROXY Only CLOUD
    if (CoreConfigAccount.PROXY && CoreConfigAccount.PROXY_TYPE === ProxyType.CLOUD) {
      if (CoreConfigAccount.WAF === false) {
        if (CoreNavigation.items.get('analytics').children.has('analytics.waf')) {
          CoreNavigation.items.get('analytics').children.delete('analytics.waf');
        }
      }
      if (CoreConfigAccount.BOT === false) {
        if (CoreNavigation.items.get('analytics').children.has('analytics.bot')) {
          CoreNavigation.items.get('analytics').children.delete('analytics.bot');
        }
      }
      if (CoreConfigAccount.BOT === false && CoreConfigAccount.WAF === false) {
        if (CoreNavigation.items.get('configuration').children.has('configuration.policies')) {
          CoreNavigation.items.get('configuration').children.delete('configuration.policies');
        }
        if (CoreNavigation.items.get('configuration').children.has('configuration.signatures')) {
          CoreNavigation.items.get('configuration').children.delete('configuration.signatures');
        }
      }
    }

    // PROXY Disabled
    if (!CoreConfigAccount.PROXY) {
      if (CoreNavigation.items.get('configuration').children.has('configuration.assets')) {
        CoreNavigation.items.get('configuration').children.delete('configuration.assets');
      }
      if (CoreNavigation.items.get('configuration').children.has('configuration.policies')) {
        CoreNavigation.items.get('configuration').children.delete('configuration.policies');
      }
      if (CoreNavigation.items.get('configuration').children.has('configuration.certificates')) {
        CoreNavigation.items.get('configuration').children.delete('configuration.certificates');
      }
      if (CoreNavigation.items.get('configuration').children.has('configuration.signatures')) {
        CoreNavigation.items.get('configuration').children.delete('configuration.signatures');
      }
      if (CoreNavigation.items.get('analytics').children.has('analytics.waf')) {
        CoreNavigation.items.get('analytics').children.delete('analytics.waf');
      }
    }

    // BGP Disabled
    if (!CoreConfigAccount.BGP) {
      if (CoreNavigation.items.get('configuration').children.has('configuration.prefix-map')) {
        CoreNavigation.items.get('configuration').children.delete('configuration.prefix-map');
      }
    }

    // DNA Disabled
    if (!CoreConfigAccount.DNA) {
      if (CoreNavigation.items.get('configuration').children.has('configuration.dna')) {
        CoreNavigation.items.get('configuration').children.delete('configuration.dna');
      }
    }

    // RESELLER Disabled
    if (!CoreConfigAccount.RESELLER) {
      if (CoreNavigation.items.get('manage').children.has('manage.direct')) {
        CoreNavigation.items.get('manage').children.delete('manage.direct');
      }

      if (CoreNavigation.items.get('manage').children.has('manage.reseller')) {
        CoreNavigation.items.get('manage').children.delete('manage.reseller');
      }
    }

    // API ACCESS Disabled
    if (!CoreConfigAccount.API_ACCESS) {
      if (CoreNavigation.items.get('manage').children.has('manage.api')) {
        CoreNavigation.items.get('manage').children.delete('manage.api');
      }
    }

    // Whitelebel enabled #SPPROJ-9752
    if (CoreConfigMeta.WHITELABELED) {
      if (CoreNavigation.items.get('manage').children.get('manage.api').children.has('manage.api.openhybrid')) {
        CoreNavigation.items.get('manage').children.get('manage.api').children.delete('manage.api.openhybrid');
      }
    }

    // SOC Tools
    const permissions: CorePermissionSimpleType = CorePermissionSimpleInstance('ui:soc-tools');

    if (permissions.Disallowed) {
      if (CoreNavigation.items.get('manage').children.has('manage.soc')) {
        CoreNavigation.items.get('manage').children.delete('manage.soc');
      }
    }
  } catch (err: any) {
    return err;
  }

  return void 0;
}

/**
 * WhiteLabel Adjust Navigation
 * @async
 * @export
 */
export async function WhiteLabelAdjustNavigation(src: HashMap<any>): Promise<void> {
  if (!src || !Object.keys(src).length) {
    return void 0;
  }

  try {
    const { enabled, hideDocs } = src;

    if (enabled) {
      if (hideDocs) {
        if (CoreNavigation.items.get('manage').children.get('manage.api').children.has('manage.api.api-doc')) {
          CoreNavigation.items.get('manage').children.get('manage.api').children.delete('manage.api.api-doc');
        }
      }
    }
  } catch (err: any) {
    return err;
  }

  return void 0;
}
